<template>
    <div>
        <vue-html2pdf :show-layout="true" :float-layout="false" :enable-download="false" :preview-modal="true"
            margin="[10,10,10,10]" filename="PTP Conversation Report" :paginate-elements-by-height="1400" :pdf-quality="2"
            pdf-format="letter" pdf-orientation="portrait" pdf-content-width="auto" :manual-pagination="true"
            @progress="onProgress($event)" @beforeDownload="beforeDownload($event)" @hasDownloaded="hasDownloaded($event)"
            ref="html2Pdf">
            <section slot="pdf-content">
                <div class="col-md-12">
                    <div class="row" style="margin-top: 20px">
                        <div class="col-md-6">
                            <h3 class="page-header">
                                <i class="fa fa fa-calculator animated bounceInDown show-info"></i>
                                <span>{{ setheadding() }}</span>
                            </h3>
                        </div>
                        <div class="col-md-6 text-right" v-if="isprint == false">
                            <button v-if="!hidetd()" @click="generateReport()" class="btn btn-xs pdf"
                                style="margin-right: 15px;"><i class="bx bxs-file-pdf" style="font-size:30px"></i></button>
                            <button @click="refresh()" class="btn btn-refresh">
                                <i class="fa fa-refresh"></i>
                            </button>
                        </div>
                        <!-- <div class="col-md-6 text-right" v-if="viewno == 2">
                            <button @click="back()" class="btn btn-primary">
                                <i class="fa fa-backward"></i> Back
                            </button>
                        </div> -->
                    </div>
                    <div v-if="!hidetd() && isprint == false">
                        <div class="row" style="margin-left: 0px;">
                            <div class="col-md-4 row">
                                <label><b>Entry By</b></label>
                                <select v-model="entrybyid" class="form-control form-cascade-control input-small">
                                    <option v-for="filter in employees" :key="filter.id" :value="filter.id">{{ filter.name
                                    }}</option>
                                </select>
                            </div>
                            <div class="col-md-4 row">
                                <label><strong>Entry Date : From</strong></label>
                                <input class="form-control form-cascade-control input-small" type="date"
                                    v-model="entrybydatefrom">
                            </div>
                            <div class="col-md-4 row">
                                <label><strong>Entry Date : To</strong></label>
                                <input class="form-control form-cascade-control input-small" type="date"
                                    v-model="entrybydateto">
                            </div>

                        </div>
                        <div class="ptp-report-btn">
                            <button class="btn btn-primary" @click="searchrecord()">Search</button>
                            <button class="btn btn-danger" @click="refresh()">Cancel</button>
                        </div>
                    </div>
                    <div class="col-md-12 table-responsive" style="padding:0px;">
                        <div class="panel">
                            <div class="panel-body table" style="overflow-x:auto;padding-left:0px">
                                <table class="table table-bordered table-font"
                                    style="margin-bottom:0;font-size:15px!important;">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Lan No.</th>
                                            <th>Customer Name</th>
                                            <th>Message</th>
                                            <th>Followed By</th>
                                            <th>EMI Date</th>
                                            <th>Over Dues Date</th>
                                            <th>Entry Date</th>
                                            <th>Entry By</th>
                                            <th>Date of Delay</th>
                                            <th >Action</th>
                                        </tr>
                                    </thead>

                                    <tbody v-for="(item,index) in comment" v-bind:key="item.id">
                                        <tr >
                                            <td>{{ index+1 }}</td>
                                            <td>{{ item.loandetails?.manualid }}</td>
                                            <td>{{ item.customer?.name }}</td>
                                            <td>{{ item.comments }}</td>
                                            <td>{{ item.visitor != null ? item.visitor?.name : '' }}</td>
                                            <td>{{ item.emi != null ? moment(item.emi?.emidate).format("DD-MM-YYYY") : '' }}</td>
                                            <td>{{ moment(item.nextpaymentdate).format("DD-MM-YYYY") }}</td>
                                            <td>{{ moment(item.created_at).format("DD-MM-YYYY") }}<br>
                                                {{ moment(item.created_at).format("hh-mm-s A") }}</td>
                                            <td>{{ item.enteredby != null ? item.enteredby.name : '' }}</td>
                                            <td class="text-center ">
                                              <span :class="delaydate(item) >= 10 ?'badge bg-danger font-15' : 'badge bg-seagreen font-15'"> 
                                                <i class="bx bx-calendar"></i> {{ delaydate(item) }}
                                              </span>
                                            </td>
                                            <td class="" style="border-bottom:none ;display: flex;" v-if="hidetd()">
                                                <!-- <button class="" @click="updatecomment(item)">Approve</button><br/> -->
                                                <button class="ptp-btn" style="background: #058fff;"
                                                    v-if="loginusercheck(200)"
                                                    @click="approvecomment(item)">Approve</button>
                                                <button class="ptp-btn" style="background: #009B35;padding: 5px 10px;"
                                                    @click="approvaledit(item)">Edit</button>
                                                <button class="ptp-btn" style="background: #dc3545;padding: 5px 8px;"
                                                    @click="rejectcomment(item)">Decline</button>
                                            </td>
                                            <td v-else>
                                                <div v-if="item.approvestatus==1 ">
                                                    <span style="color:green">{{ item.approvedby !=null  ? item.approvedby.name : '' }}</span>
                                                </div>
                                                <div v-else>
                                                    <span v-if="item.approvestatus==2" style="color:red"> Declined</span>
                                                    <span v-else style="color:black">Not Approved</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <div v-if="page1(index)" class="html2pdf__page-break" style="margin-bottom:20px;"></div>  
                                    </tbody>
                                </table>
                            </div>

                            <div v-if="commentbox" class="col-md-12 row">
                                <div class="col-md-8">
                                    <label>Conversation</label>
                                    <textarea class="form-control form-cascade-control input-small" v-model="conversation"
                                        cols="20" rows="2" style="height:83px;"></textarea>
                                </div>
                                <div class="col-md-4 flex-around-row">
                                    <button class="btn btn-prime" @click="submitconversation()">Submit</button>

                                    <button class="btn btn-danger" @click="cancelconversation()">Cancel</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-12" style="margin-top: 20px">
                        <nav aria-label="Page navigation example" style="text-align: right !important">
                            <paginate v-if="isprint == false" style="margin: 0px !important; float: right"
                                :page-count="noofpages" :click-handler="clickCallback" :prev-text="'&laquo;'"
                                :next-text="'&raquo;'" :container-class="'pagination'">
                            </paginate>
                        </nav>
                    </div>
                </div>
            </section>
        </vue-html2pdf>
    </div>
</template>
<script>
import moment from "moment";
import Paginate from "vuejs-paginate";
import { mapGetters } from 'vuex'
import Constants from "../../components/utilities/Constants.vue";
import VueHtml2pdf from 'vue-html2pdf'

export default {
    mixins: [Constants],
    components: { Paginate, VueHtml2pdf },
    data(){
        return {
            currentpage: 1,
            noofrec: 10,
            comment: [],
            totalcommentcount: 0,
            moment: moment,
            commentbox: false,
            conversation: '',
            bottom: '',
            conversationid: '',
            entrybyid: 0,
            tempindex:0,
            entrybydatefrom: '',
            entrybydateto: '',
            record: [],
            isprint: false,
            progress: 0,
            

        }
    },
    computed: {
        ...mapGetters([
            'viewno', 'loggedinuser', 'edititem', 'employees'
        ]),
        noofpages() {
            return Math.ceil(parseInt(this.totalcommentcount) / parseInt(this.noofrec))
        },
       
        
    },
    watch: {
        '$route.params.isdraft'() {
            this.refresh()
        },
        
    },
    mounted() {
         this.refresh();
         
        
    },

    methods: {
        tempindexfun(){
            
            var newcount = this.tempindex+1;
            this.tempindex = newcount+1;
            console.log(newcount);
            
        },
        clickCallback(currentpage) {
            this.currentpage = currentpage
            this.$store.commit("assigncurrentpage", currentpage);
            this.refresh();
        },
        refresh() {
            this.$store.dispatch("fetchemployees")
            let param = { isdraft: this.$route.params.isdraft, currentpage: this.currentpage, noofrec: this.noofrec }
            this.$http.post('api/loan/emi/visitcomments/all', param)
                .then((response) => this.processDetailResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
            this.initialize();
        },
        processDetailResponse(data) {
            //this.comment = data.visitcomment.filter(block => block.approvestatus== 1)
            this.comment = data.visitcomment
            this.totalcommentcount = data.count
        },
        setheadding() {
            if (this.$route.params.isdraft == 1) {
                return "PTP Conversation Approval"
            } else if (this.$route.params.isdraft == 0) {
                return "PTP Conversation Report"
            }
        },
        approvecomment(item) {
            let param = { id: item.id, column: 'isdraft', value: 0, approvestatus: 1 }
            this.$http.post('api/visitcomment/updatecolumn', param)
                .then((response) => this.processDetailUpdateResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
        },
        processDetailUpdateResponse() {
            this.refresh()
        },
        rejectcomment(item) {
            if(confirm('Do you want to decline this record?')){
                 // this.comment.splice(index,1)
                let param = { id: item.id, emiid: item.emiid, approvestatus: 2, column: 'isdraft', value: 0 }
                this.$http.post('api/visitcomment/updatecolumn', param)
                    .then((response) => this.processRejectCommentResponse(response.data))
                    .catch((err) => { console.log(err) });
            }
        },
        processRejectCommentResponse(data) {
            this.comment = data;
            this.refresh();
        },
        hidetd() {
            if (this.$route.params.isdraft == 1) {
                return true
            } else if (this.$route.params.isdraft == 0) {
                return false
            }
        },
        approvaledit(item) {
            console.log(item)
            this.bottom = document.body.scrollHeight
            window.scrollTo(0, this.bottom);
            this.commentbox = true;
            this.conversationid = item.id;
            this.conversation = item.comments;
        },
        cancelconversation() {
            this.commentbox = false;
            this.refresh()
        },
        submitconversation() {
            let param = {
                conversationid: this.conversationid,
                conversation: this.conversation
            }
            this.$http.post('api/loan/updateconversation', param)
                .then((response) => this.processConversationResponse(response.data))
                .catch((error) => {
                    console.log(error)
                });
        },
        processConversationResponse(data) {
            this.commentbox = false;
            this.refresh();
            let conversationdata = data;
            return conversationdata
        },

        searchrecord() {
            if ((this.entrybyid != null && this.entrybyid != 0) || ((this.entrybydatefrom != null && this.entrybydatefrom != '') && (this.entrybydatefrom != null && this.entrybydatefrom != ''))) {
                this.$store.commit('assignloadingstatus', 1);
                let param = {
                    entrybyid: this.entrybyid,
                    entrybydatefrom: this.entrybydatefrom,
                    entrybydateto: this.entrybydateto
                }
                this.$http.post('api/loan/searchconversation', param)
                    .then((response) => this.processRecordResponse(response.data))
                    .catch((err) => {
                        console.log(err)
                    });
            }
            else {
                this.$notify({
                    text: 'Select entry by or date',
                    type: 'error',
                    duration: '1000',
                    speed: '800',
                    position: 'top center'
                })

            }

        },
        processRecordResponse(data) {
            this.$store.commit('assignloadingstatus', 0);
            //this.comment = data.filter(block => block.approvestatus== 1)
         
            this.comment = data;
            // console.log("data repesent")
            // console.log(data)

            this.totalcommentcount = data.count
        },
        initialize() {
            this.entrybyid = 0
            this.entrybydate = ''
        },
        generateReport() {
            this.$store.commit('assignloadingstatus', 1)
            this.isprint = true
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        hasDownloaded() {
            this.$store.commit('assignloadingstatus', 0)
            this.isprint = false
        },
        async beforeDownload({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(11)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                }
            }).save()
        },
        page1(index) {
            // (index!=0 && index==6 && index!=8) || (index!=0 && index!=6 && index%6==0)
            if (index != 0 && index == 4) {
                return true
            }
            // if(index!=0 &&  index!=6 && index%6==0){
            if ((index - 4) % 9 == 0) {
                return true
            }
            return false
        },
       
        delaydate(item){
            const currentdate = moment().toDate("YYYY-MM-DD");
            const diffInDays = moment(currentdate).diff(moment(item?.emi?.emidate), "days");
            return diffInDays
        }
        

    },
}
</script>


